import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import ExternalLink from "../components/blog/ExternalLink";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return  <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="How To Go Grocery Shopping With A Baby"
            description="There is no need to stress yourself out over grocery shopping with a baby. With the right preparations, you can handle this without breaking a sweat!"
            location={location}
            pageType="article"
        />
        <HeroSection>
            <BlogTitle>
                How To Go Grocery Shopping With A Baby
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/How-To-Go-Grocery-Shopping-With-A-Baby.jpg" alt="How To Go Grocery Shopping With A Baby"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@pepperguy184?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jomjakkapat Parrueng</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-bill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Grocery shopping can get the best of us even without additional hurdles like going with a small human who doesn't have control over their basic functions and needs.
        </Paragraph>
        <Paragraph>
            But sometimes, you just don’t have a choice - there’s no one to help, no one to go shopping for you and no one to stay home with the baby, and you’re running out of edible things in the house.
        </Paragraph>
        <Paragraph>
            First of all, you are incredibly brave (and possibly lacking some self-preservation skills) for taking on the mission of going grocery shopping with a baby.
        </Paragraph>
        <Paragraph>
            Second of all, even though it is a difficult task, it’s nothing you can’t handle. Plenty of moms do it more or less on the regular, so there’s nothing to worry about.
        </Paragraph>
        <Paragraph>
            Here are some <InternalLink to="/grocery-shopping-tips/">grocery shopping tips</InternalLink> that will help you breeze through your baby-inclusive shopping trip and prevent you from feeling like you’ve just ran an ultra marathon in the process.
        </Paragraph>
        <BlogHeading2>
            Be careful with the car seat
        </BlogHeading2>
        <Paragraph>
            You’ve probably seen it all over social media and parenting blogs - simply fasten the baby’s car seat (with the baby in it) to the front of the shopping cart, and voila! An easy-peasy solution to what to do with a baby during shopping.
        </Paragraph>
        <Paragraph>
            However, this comes with a whole host of potential dangers. Every year almost 24,000 children end up in accidents involving car seats and shopping carts that are not meant to hold them.
        </Paragraph>
        <Paragraph>
            The only way you can safely fasten your infant car seat to the shopping cart is if the cart was specifically designed for that. If you live and shop in North America (the US and Canada), you can <ExternalLink to="http://www.shoppingcartsafety.com/find-safe-dock/">visit a website</ExternalLink> and check whether your local grocery store has shopping carts with so-called Safe-Docks.
        </Paragraph>
        <Paragraph>
            Once again, be extremely careful with putting your baby’s car seat on top of the grocery cart. If you’re not 100% sure that the cart was designed for it, it’s best to avoid it altogether.
        </Paragraph>
        <Paragraph>
            Instead, you can opt for different methods of carrying your baby through the store.
        </Paragraph>
        <BlogHeading2>
            Use a baby carrier
        </BlogHeading2>
        <Paragraph>
            If your grocery shop trip isn’t too long and your baby doesn't get fussy in a baby carrier, wearing your baby is a good option. Baby carriers are great if you don’t have too much to buy and can comfortably carry your baby for a while.
        </Paragraph>
        <Paragraph>
            Problems may arise during bagging and putting away your groceries in the car. You can always ask an employee to help you with bagging, and you can return your baby to their car seat before you start loading up the groceries.
        </Paragraph>
        <Paragraph>
            However, carrying your baby throughout the supermarket may not be ideal for everyone. Or even for most people.
        </Paragraph>
        <Paragraph>
            Thankfully, there are other, potentially easier, ways to go about this mission.
        </Paragraph>
        <BlogHeading2>
            Get a shopping cart hammock
        </BlogHeading2>
        <Paragraph>
            A shopping cart hammock is pretty much exactly what it sounds like - a hammock that you can attach to the edges of a shopping cart and in which your little one can snooze away, oblivious to the stresses of grocery shopping. Who doesn't love a nice hammock to relax in?
        </Paragraph>
        <Paragraph>
            Wherever you’re shopping for this product, it is important to keep in mind whether it is safe and secure. It has to be made from non-toxic materials (such as cotton), and in accordance with the safety regulations in your region. For the United States, a baby hammock like this has to be tested and compliant with the <ExternalLink to="https://www.cpsc.gov/Regulations-Laws--Standards/Statutes/The-Consumer-Product-Safety-Improvement-Act">Consumer Product Safety Improvement Act (CPSIA)</ExternalLink>.
        </Paragraph>
        <Paragraph>
            Take a look at a baby hammock example in the pic below.
        </Paragraph>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-4-image2.jpg" alt="Baby smiling in baby hammock"/>
            <em>From Amazon.com</em>
        </ImageWithCaption>
        <BlogHeading2>
            Take advantage of the stroller
        </BlogHeading2>
        <Paragraph>
            Is there a law that says you must use a shopping cart whenever you’re in a store? Not that we’re aware of.
        </Paragraph>
        <Paragraph>
            So what’s stopping you from utilizing your child's stroller as a makeshift shopping basket? You can use the storage space underneath for your groceries - and if there’s not enough space, you can always take a larger diaper bag or a reusable grocery bag with you and hang it on the stroller.
        </Paragraph>
        <Paragraph>
            Keep in mind that each stroller has a specific weight limit. Don’t exceed yours by getting too many grocery items at once.
        </Paragraph>
        <BlogHeading2>
            Other essential tips
        </BlogHeading2>
        <Paragraph>
            Now that you’ve decided where you’re going to place your kid during store time, let’s take a look at all the other things you need to do to make this as easy on you as possible.
        </Paragraph>
        <BlogHeading3>
            Plan what you’re going to buy
        </BlogHeading3>
        <Paragraph>
            Going to a store without a clear plan on what you’re going to buy is unwise for multiple reasons:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>
                Increased likelihood of impulse buys that only add to your grocery budget
            </UnorderedListItem>
            <UnorderedListItem>
                Spending more time than you need to in the store
            </UnorderedListItem>
            <UnorderedListItem>
                Never having the right ingredients for your meals
            </UnorderedListItem>
            <UnorderedListItem>
                Forgetting to buy things you need
            </UnorderedListItem>
            <UnorderedListItem>
                Wasting bought food items you don't end up using, and more
            </UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Having a shopping list is crucial for any shopper, regardless of their situation. For moms shopping alone with their babies, it is an indispensable tool to get everything done in an optimal time frame.
        </Paragraph>
        <Paragraph>
            To make it even simpler, using a grocery shopping app instead of old-fashioned note paper brings in an added layer of efficiency.
        </Paragraph>
        <BlockQuote>
            <InternalLink to="/#download-section">Going Shopping</InternalLink> is a free shopping list app specially designed to make a grocery trip a walk in the park. With and without baby.
        </BlockQuote>
        <BlogHeading3>
            Pack light
        </BlogHeading3>
        <Paragraph>
            You will already have your hands full with the shopping bags (and the baby, of course). There is no need to overload your diaper bag with too many just in case items.
        </Paragraph>
        <Paragraph>
            Here is an example of grocery shopping baby essentials:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>
                Change of clothes for the baby
            </UnorderedListItem>
            <UnorderedListItem>
                Pacifier to calm them down (if they can use the pacifier)
            </UnorderedListItem>
            <UnorderedListItem>
                A baby bottle if they’re drinking anything
            </UnorderedListItem>
            <UnorderedListItem>
                A breastfeeding cover if you’re still breastfeeding
            </UnorderedListItem>
            <UnorderedListItem>
                Extra breast pads if necessary
            </UnorderedListItem>
            <UnorderedListItem>
                Diapers
            </UnorderedListItem>
            <UnorderedListItem>
                Box of baby wipes
            </UnorderedListItem>
            <UnorderedListItem>
                Hand sanitizer
            </UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Ideally, you won’t be at the store long enough for the baby or you to need anything more than that (if that).
        </Paragraph>
        <BlogHeading3>
            Know where to park
        </BlogHeading3>
        <Paragraph>
            We cannot emphasize enough how important it is to pick a parking space that works for you. It has to be near the cart return area so you don’t have to trek all the way back to return your cart once you’ve packed everything (possibly even the baby). It’s a bonus if the spot is in the shade, especially during warm months.
        </Paragraph>
        <Paragraph>
            If there are new mom parking spots available, definitely consider them. But if they are too far from cart return, maybe it’s best to use a regular spot that is more convenient for you.
        </Paragraph>
        <BlogHeading3>
            One store only
        </BlogHeading3>
        <Paragraph>
            When you shop by yourself, you may have a habit of hitting several stores in one go. Perhaps you prefer different brands of items or store brands, or maybe some things are cheaper in one grocery store than the others.
        </Paragraph>
        <Paragraph>
            We urge you to reconsider this notion when shopping with a baby. It would be best to limit yourself to one store only, and not hang too long. A quick in and out where you get all the essential stuff is ideal when you have a little one who doesn't have much patience for dull daily life errands.
        </Paragraph>
        <Paragraph>
            Entrust a more lengthy shopping trip to someone else, or leave the baby at home with family or friends if you know you’ll be out for a while
        </Paragraph>
        <BlogHeading2>
            Bottom line: You’ve got this!
        </BlogHeading2>
        <Paragraph>
            There is no need to stress yourself out. Anyone would be lying if they said that going grocery shopping by yourself with a baby in tow was all rainbows and daisies. But with enough preparation, there is no reason to believe you can’t handle something like this.
        </Paragraph>
        <Paragraph>
            Just remember to pack what you need, take your grocery list with you, and to ensure you have a safe and comfortable space to put your baby in while you’re walking from one aisle to the next.
        </Paragraph>
        <Paragraph>
            There’s nothing you can’t do if you just set your mind to it.
        </Paragraph>
        <Paragraph>
            Before you go, you might want to check out Going Shopping, our <InternalLink to="/#download-section">free shopping list app</InternalLink> that’ll definitely help in this entire process.
        </Paragraph>
    </StyledMainContent>
        </BlogPostWrapper>
}

export default BlogPost;